import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { authTokenStorageKey } from "../Context/UserProvider";
import axiosRetry from 'axios-retry';
export const apiEndpointStorageKey = "apiEndpoint";

export enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export interface LaravelValidationError {
  message: string;
  errors: Record<string, string[]>;
}

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers) {
    const authToken = localStorage.getItem(authTokenStorageKey);

    if (authToken) {
      //eslint-disable-next-line
      config.headers.Authorization = `Bearer ${authToken}`;
    }
  }

  config.withCredentials = true;
  return config;
});

axios.interceptors.response.use(
    async (response) => response,
    async (error: AxiosError) => {
      const errorData = error.response ? error.response.data : { message: 'Network error or timeout', errors: [] };
      return Promise.reject(errorData);
    }
);

axiosRetry(axios, {
  retries: 0,
  retryCondition: () => false,
});

async function request<T = void>(
  endpoint: string,
  method: Method = Method.GET,
  requestParams: Object = {},
  api: boolean = true
) {
  let serverBaseUrl =
    localStorage.getItem(apiEndpointStorageKey) ??
    process.env.REACT_APP_API_BASE_URL;

  if (api) {
    serverBaseUrl += "/api";
  }

  const url = serverBaseUrl + endpoint;

  const requestConfig: AxiosRequestConfig = {
    method,
    url,
    ...requestParams,
    maxRedirects: 0,
    timeout: 10000,
  };

  try {
    const { data: response } = await axios.request<T>(requestConfig);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.message);
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
}

export default request;
